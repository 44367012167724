const TSV_FILE_FORMAT = 'TSV'
const XLS_FILE_FORMAT = 'XLS'

const TYPES_CONTENT = 'TYPES'
const VOCABULARIES_CONTENT = 'VOCABULARIES'
const ENTITIES_CONTENT = 'ENTITIES'

const ALL_COLUMNS = 'ALL_COLUMNS'
const VISIBLE_COLUMNS = 'VISIBLE_COLUMNS'
const COLUMNS_OPTIONS = [ALL_COLUMNS, VISIBLE_COLUMNS]

const ALL_PAGES = 'ALL_PAGES'
const CURRENT_PAGE = 'CURRENT_PAGE'
const SELECTED_ROWS = 'SELECTED_ROWS'
const ROWS_OPTIONS = [ALL_PAGES, CURRENT_PAGE, SELECTED_ROWS]

const PLAIN_TEXT = 'PLAIN'
const RICH_TEXT = 'RICH'
const VALUES_OPTIONS = [PLAIN_TEXT, RICH_TEXT]

export default {
  TSV_FILE_FORMAT,
  XLS_FILE_FORMAT,
  TYPES_CONTENT,
  VOCABULARIES_CONTENT,
  ENTITIES_CONTENT,
  COLUMNS_OPTIONS,
  ROWS_OPTIONS,
  VALUES_OPTIONS,
  ALL_COLUMNS,
  VISIBLE_COLUMNS,
  ALL_PAGES,
  CURRENT_PAGE,
  SELECTED_ROWS,
  PLAIN_TEXT,
  RICH_TEXT
}
